:root {
  --primary-color: #ffffff;
  --secondary-color: #192263;
  --text-color: black;
  --second-backdrop: #d8d8d8;
  --filtero: invert(0%) sepia(3%) saturate(0%) hue-rotate(311deg) brightness(95%) contrast(100%);
  --blog-color: #ffffff;
}

[data-theme="dark"] {
  --primary-color: #272a2d;
  --secondary-color: #6f72cf;
  --text-color: rgb(218, 218, 218);
  --second-backdrop: #1E1E1E;
  --filtero: invert(100%) sepia(0%) saturate(0%) hue-rotate(101deg) brightness(103%) contrast(101%);
  --blog-color: #191a1d;
}



* {
  scrollbar-color: white;
}

.body-2 {
  /* background-color: red; */
  /* border: 1px solid black; */
}

/* body{
  background-color: #;
} */

.blogLogist{
  text-align: center;
  margin-bottom: 4rem;
}

.darkbuttonholder{
  /* background-color: transparent; */
  border: none;
  /* background-color: red; */
  max-width: 6%;
  margin-left: 94%;
  margin-top: 0.2%;
  
}

.darkmodebutton{
  /* max-width: 5%; */
  /* margin-left: 100%; */
  filter: var(--filtero);
  transition: all 0.3s ease-in-out;
}

.darkbuttonholder :hover{
  filter: invert(73%) sepia(73%) saturate(815%) hue-rotate(355deg) brightness(102%) contrast(110%);
  transition: all 0.3s ease-in-out;
}



.hero {
  /* background-color: #ffc0cb; */
  align-self: center;
  font-weight: bold;
  margin-bottom: 2rem;
  white-space: nowrap;
  width: 80%;
  padding: 1rem;
  color: var(--text-color);
  transition: all 0.3s ease-in-out;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid orange; /* The typwriter cursor */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 2.5s steps(30, end),
    blink-caret .5s step-end infinite;
}

.blogPost{
  /* background-color: red; */
  max-width: 55%;
  text-align: left;
  font-family: 'Lora';
  font-size: 1.2rem;
  color: var(--text-color);

  padding: 4rem;
  background-color: var(--blog-color);
  margin-bottom: 3rem;
  margin-top: 1rem;
  transition: all 0.3s ease-in-out;
}


.blogContent > * {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  transition: all 0.3s ease-in-out;
}

.blogContent pre{
  background-color: var(--second-backdrop);
  padding: 1.5rem;
  white-space: pre-wrap;
  overflow: hidden;
}


.blogContent h4{
  font-size: 1.4rem;
  font-weight: bolder;
}

.blogContent img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;

}

.blogContent ul{
    list-style-type: disc;
}

.paragraph {
  font-size: 1.3rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
  /* background-color: red; */
  padding: 1rem;
  margin: auto;
  width: 55%;
  opacity: 0;
  animation: fadeInAnimation cubic-bezier(0.1, -0.6, 0.2, 0) 3s;
  /* animation: flicker 2s steps(1) forwards; */
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  color: var(--text-color);
  transition: all 0.3s ease-in-out;
  
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange }
}

@keyframes flicker {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.1;
  }
  60%{
    opacity: 1;
  }

  70%{
    opacity: 0.1;
  }

  75%{
    opacity: 1;
  }

  80%{
    opacity: 0.1;
  }

  95%{
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.home_container{
  font-family: 'Sono', sans-serif;
  text-align: center;
  /* width: 15rem; */
  max-width: 70%;
  max-height: 50%; 
  
}

.blog{
  /* background-color: #29e2ff;
  width: 8rem;
  border-radius: 20px;
  border: 1px solid black;
  box-shadow:  1px 1px 3px -1px #000; */
  margin: auto;
  white-space: nowrap;
  text-align: center;
  color: var(--text-color);
  transition: all 0.3s ease-in-out;
}

/* .header div.blog:hover{
  background-color: #ddbc44;
} */

.blogHolder{
  background-color: var(--primary-color);
  display: flex;
  transition: all 0.3s ease-in-out;
  min-height: 85vh;
  object-fit: fill;
  align-items: center;
  justify-content: center;
}

.blog-post-grid{
  margin-top: 5rem;
  display: flex;
  min-width: 90%;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  margin-left: 2%
}

.blog-post-item{
  transform: translate3d(0, 0, 0);
  transition-duration: 0.2s;
  transition: transform ease;
  /* background-color: blue; */

  min-width: 35rem;
  max-width: 35rem;
  display: flex;

}

.big-card :hover{
  transform: translate3d(-6px, -6px, -6px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
  transition-duration: 0.2s;
  cursor: pointer;
}

.big-card{
  min-width: 35rem;
  margin: 2rem;
  /* margin-left: 28rem; */
  margin-bottom: 4rem;
  font-family: 'Sono';

  transform: translate3d(0, 0, 0);
  transition-duration: 0.2s;
  transition: transform ease;

  /* background-color: red; */
}


.big-link{
  text-decoration: none;
  color: var(--text-color)
}

.big-link :hover{
  color: var(--text-color);
}

.blog-title{
  font-weight: 900;
  font-size: 1.5rem;
  pointer-events: none;
}

.blog-date{
  font-style: italic;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  pointer-events: none;
}

.blog-desc{
  margin-top: 0.5rem;
  pointer-events: none;
}


.little-card{
  text-decoration: none;
}


.front{
  display: flex;
  background-color: var(--primary-color);
  transition: all 0.3s ease-in-out;
  /* width: 100%;
  height: 100%; */
  min-height: 90vh;
  object-fit: fill;
  align-items: center;
  justify-content: center;
  /* background-color: white; */
}

.frontButton{
  display: flex;
  text-decoration: none;
  
}

.header ul {
  /* background-color: blue; */
  padding: 0;
  margin: 0;
  margin-right: 8rem;
  list-style: none;
  display: flex;
  gap: 3.5rem;
}

.header {
  height: 6rem;
  /* background-color: red; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0rem;
  background-color: var(--primary-color);
  transition: all 0.3s ease-in-out;
  color: var(--text-color);
  transition: all 0.3s ease-in-out;
  border-top: 1px solid #000;
}

.header a {
  color: inherit;
  font-family: 'Sono', sans-serif;
}

.header a:hover{
  color: var(--secondary-color);
  transition: all 0.2s ease-in-out;
  
}

.header ul a:link { 
  text-decoration: none; 
  font-size: 1.3rem;
}


.header li:hover {
  /* text-decoration:underline; */
  transition: all 0.3s ease-in-out;
}

.siteTitle {
  font-size: 2rem;
  font-weight: bold;
  margin-left: 5rem;
  text-decoration: none;
}

.footer {
  background-color: var(--secondary-color);
  transition: all 0.3s ease-in-out;
  min-height: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #000;
}

.social-buttons{
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  margin-right: 2.5rem;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(328deg) brightness(107%) contrast(102%);
}

.social-buttons :hover{
  filter: invert(24%) sepia(27%) saturate(4143%) hue-rotate(140deg) brightness(91%) contrast(98%);
  
}

.social-circle{
  height: 55px;
  width: 55px;
  max-height: 50px;
  max-width: 50px;
  padding: 8px;
}


.resumeDiv{
  height: 100%;
  width: 100%;
  /* display: flex;
  justify-content: center; */
  /* background-color: red; */
}



.resumeSectionName{
  background-color: blue;
}

.something img{
  filter: var(--filtero);
  transition: all 0.3s ease-in-out;
}

.resumeHeader{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  /* background-color: red; */
}

.resumeHeader img{
  filter: var(--filtero);
  transition: all 0.3s ease-in-out;
  /* margin-right: 1rem; */
}

.entryTitle{
  /* background-color: red; */
  font-weight: bold;
  font-size: 1.3rem;
  white-space: nowrap;
  text-align: left;
  margin-top: 0.8rem;


}

.entryHolder{
  font-size: 1rem;
  text-align: left;
  margin-top: -0.5rem;
  color: var(--secondary-color);
  transition: all 0.3s ease-in-out;
}

.entryLogistics{
  display: flex;
  justify-content: space-between;
  margin-top: -1rem;
  font-size: 1rem;
}


.entry{
  margin-bottom: -0.85rem;
  align-items: left;
  text-align: left;
  font-weight: lighter;
  font-size: 1rem;
}

.resumeEntry{
  padding-left: 0px;
  margin-left: 0px;
  margin-bottom: 2rem;
  margin-top: -0.5rem;

}

.down{
  color:var(--secondary-color);
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
}

.down:hover{
  width: 100%;
  transform: scale(1.1);
  color: var(--secondary-color);
  transition: all 0.3s ease-in-out;
}

.s{
  margin-top: 2rem;
  margin-right: 0.2rem;
  height: 35px;
  width: 35px;
}


.button{
  color: black;
}

.color{
  color:var(--secondary-color);
  transition: all 0.3s ease-in-out;
}

.other{
  color: #41b9cb;
}

.duh{
  color: #8f41cb;
}

.well{
  color: #cbad41;
}

.resumeHeader img{
  margin: 5px;
}

.aboutImage{
  border-radius: 50%;
  height: 20rem;
  width: 20rem;
  margin: auto;
}

.aboutVis{
  margin-bottom: 2rem;
}

.aboutInfo{
  margin: auto;
  text-align: center;
  max-width: 70%;
  font-size: 1.1rem;
  font-family:'Courier New', Courier, monospace;
  color: var(--text-color);
  transition: all 0.3s ease-in-out;
}

.aboutMe{
  display: block;
  padding: 4rem;
}

.resume-button{
  /* background-color: red; */
  text-align: center;
}

.aboutLinks{
  font-weight: lighter;
  margin-left: 0.5rem;
  font-size: 1rem;
  text-decoration: none;
  color: var(--text-color);
  transition: all 0.3s ease-in-out;
}

.aboutLinks:hover{
  color:var(--secondary-color);
  transition: all 0.3s ease-in-out;
  text-decoration: underline;
}

.aboutWho{
   margin: auto;
   display: flex;
   align-items: center;
}

.something{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  /* min-width: 50%; */
  max-width: 68vw;
  margin: auto;
}

.App-header {
  min-height:86vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: var(--primary-color);
  transition: all 0.3s ease-in-out;
  color: black;
  
}

.Res-header{
  min-height:86vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: var(--primary-color);
  transition: all 0.3s ease-in-out;
  color: var(--text-color);
}



@media only screen and (max-width: 1200px) {
  .hero{
    width: 100%;
  }


  .blog-post-item{
    max-width: 20rem;
    min-width: 20rem;
    margin-right: 50%;
    
  }

  .big-card{
    max-width: 20rem;
    min-width: 20rem;
    
  }

}

@media only screen and (max-width: 950px) {
  body {
    font-size: 1px;
    line-height: 1.5;
    width: 100%;
    margin: 0 auto;
  }

  .blog-post-item{
    /* max-width: 10rem;
    min-width: 10rem; */
    margin-right: 15rem;
    
  }

  .big-card{
    /* max-width: 10rem;
    min-width: 10rem;
    margin-left: 10rem; */
  }

  .paragraph{
    font-size: 14px;
    width: 200px;
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding: 0.5rem;
    border: 1px solid var(--text-color);
  }

  .header{
    /* display: none; */
    /* flex-direction: column; */
    height: 5rem;
    gap: 0rem;
    /* height: 6rem; */
  }

  /* .darkbutt{
    background-color: red;
    max-width: 2rem;
    display: flex;
  } */

  .header ul a:link { 
    text-decoration: none; 
    font-size: 1rem;
    padding: 0.2rem;
    
  }

  .header ul {
    margin-right: 1rem;
    /* background-color: red; */
    /* padding: 2rem; */
    gap: 1rem;
    margin-left: -27rem;
    z-index: 2;
  }
  
  
  .siteTitle{
    font-size: 1.5rem;
    margin-left: 2rem;
    margin-bottom: 0.4rem;
    z-index: 3;
  }

  .big-card{
    margin-left: 35%;
  }


  .darkbuttonholder{
    width: 30rem;
    max-width: 5%;
    margin-bottom: 0.1rem;
    /* margin-left: 0.1rem; */
    /* background-color: red; */
    
  }



  .blog{
    width: 3.5rem;
  }

  .hero{
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
    /* animation: none; */
    border-right: 0;
    width: 100%;
    /* background-color: rebeccapurple; */
    /* max-width: fit-content; */
  }

  .home_container{
    max-width: 100%;
    max-height: 70%; 
    /* background-color: red; */
    /* margin-top: -2rem; */
    
  }

  .footer{
    /* min-height: 1rem; */
    padding-top: 2px;
    padding-bottom: 2px;  
  }

  .social-circle{
    height: 42px;
    width: 42px;
    max-height: 42px;
    max-width: 42px;
    padding: 8px;
  }

  .social-buttons{
    margin-right: 1rem;
  }

  .aboutImage{
    height: 10rem;
    width: 10rem;
  }

  .aboutInfo{
   max-width: 100%;
    font-size: 0.9rem;
    text-align: center;
  }

  .something{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 4rem;
    /* min-width: 50%; */
    max-width: 68vw;
    margin: auto;
  }

  .aboutWho{
    margin-top: 0.5rem;
  }

  .App-header{
    display: flex;
    /* background-color: red; */
    
  }


  .Res-header{
    /* zoom: 0.3;  */
    -moz-transform: scale(0.2);
    -ms-transform:scale(0.49); /* IE 9 */
    -webkit-transform:scale(0.57); /* Safari and Chrome */
    -o-transform:scale(0.49); /* Opera */ 
    flex: 0 0 auto;
    margin-top: -35rem;
    margin-bottom: -34rem;
    /* -moz-transform-origin: 0 0; */
    /* background-color: red; */
  }
  

}
